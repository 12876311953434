


import FETCHING from '../../library/fetching'
import UMUM from '../../library/umum'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form : {
        id : '',
        tahun_anggaran  : '',
        kd_klpd : '',
        nama_klpd : '',
        jenis_klpd : '',
        kd_satker  : '',
        kd_satker_str  : '',
        nama_satker : '',
        kd_rup  : '',
        kd_rup_lokal : '',
        kd_kegiatan : '',
        kd_komponen : '',
        kd_subkegiatan  : '',
        pagu  : '',
        mak : '',
        sumber_dana : '',
        tahun_anggaran_dana : '',
        asal_dana_klpd : '',
        asal_dana_satker : '',
        status_aktif_rup : '',
        status_delete_rup : '',
        status_umumkan_rup : '',

        nama_subkegiatan : '',
        kd_subkegiatan_str : '',
        nama_kegiatan : '',
        kd_kegiatan_str : '',
        nama_program : '',
        kd_program_str : '',

        type_kegiatan : '',

      },

      filterku : {
        kd_satker : '',
        tahun_anggaran  : '',
        type_kegiatan : '',
        status_umumkan_rup : '',
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
    
      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : true,


      mdl_detile: false,
      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,


      FETCHING : FETCHING,
      UMUM : UMUM,
      DATA_MASTER : DATA_MASTER,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_CT_RUP_DRAFT + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit,


              kd_satker : this.filterku.kd_satker,
              tahun_anggaran  : this.filterku.tahun_anggaran,
              type_kegiatan : this.filterku.type_kegiatan,
              status_umumkan_rup : this.filterku.status_umumkan_rup,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_CT_RUP_DRAFT + "Add", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_CT_RUP_DRAFT + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_CT_RUP_DRAFT + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){
        this.form.id = data.id;

          this.form.id = data.id ;
          this.form.tahun_anggaran  = data.tahun_anggaran ;
          this.form.kd_klpd = data.kd_klpd ;
          this.form.nama_klpd = data.nama_klpd ;
          this.form.jenis_klpd = data.jenis_klpd ;
          this.form.kd_satker  = data.kd_satker ;
          this.form.kd_satker_str  = data.kd_satker_str ;
          this.form.nama_satker = data.nama_satker ;
          this.form.kd_rup  = data.kd_rup ;
          this.form.kd_rup_lokal = data.kd_rup_lokal ;
          this.form.kd_kegiatan = data.kd_kegiatan ;
          this.form.kd_komponen = data.kd_komponen ;
          this.form.kd_subkegiatan  = data.kd_subkegiatan ;
          this.form.pagu  = data.pagu ;
          this.form.mak = data.mak ;
          this.form.sumber_dana = data.sumber_dana ;
          this.form.tahun_anggaran_dana = data.tahun_anggaran_dana ;
          this.form.asal_dana_klpd = data.asal_dana_klpd ;
          this.form.asal_dana_satker = data.asal_dana_satker ;
          this.form.status_aktif_rup = data.status_aktif_rup ;
          this.form.status_delete_rup = data.status_delete_rup ;
          this.form.status_umumkan_rup = data.status_umumkan_rup ;
          this.form.type_kegiatan = data.type_kegiatan ;

       


          this.form.nama_subkegiatan = data.nama_subkegiatan ;
          this.form.kd_subkegiatan_str = data.kd_subkegiatan_str ;
          this.form.nama_kegiatan = data.nama_kegiatan ;
          this.form.kd_kegiatan_str = data.kd_kegiatan_str ;
          this.form.nama_program = data.nama_program ;
          this.form.kd_program_str = data.kd_program_str ;

    },

    syncKode : function() {
      fetch(this.$store.state.url.URL_CT_RUP_DRAFT + "syncData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun_anggaran: this.filterku.tahun_anggaran,
          })
      }).then(res_data => {
          this.Notify('Sukses Sinkronisasi Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    autocomplete_unit_kerja : function (val, update) {
        update(() => {
          if (val === '') {}
          else {DATA_MASTER.getUnitKerjaAuto2(val, this.filterku.kd_satker)}
        })
    },

    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    // FETCHING.getContohAtocomplete('')

    const d = new Date();
    let year = d.getFullYear();

    this.filterku.tahun_anggaran = year

    var profilex = JSON.parse(localStorage.profile).profile
    this.filterku.kd_satker = profilex.kd_satker
    DATA_MASTER.getUnitKerjaAuto2('', this.filterku.kd_satker)

    this.getView();
  },
}
